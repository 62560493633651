import { IStation } from '@models'
import Image from 'next/image'
import React from 'react'
import styles from './logo.module.scss'

export default function Logo({station, lazy = undefined}: {station: IStation, lazy?: boolean}) {
    const title = `${station.meta?.title || `${station.name} לייב בשידור חי`}`
    return (
        <div className={styles.logo}>
            <Image priority={lazy? false: true} loading={lazy === true? 'lazy': undefined} src={`/images/stations/${station.id}.webp`} className={`${styles.logo} animated`} alt={title} width={470} height={300} />
        </div>
    )
}