"use client"
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

interface IIcon {
    icon: IconProp
    size?: SizeProp
    color?: string
    className?: string
    onClick?: (data?: any) => void
}
export default function Icon({icon, onClick, size, color, className}: IIcon) {
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(true)
    }, [])

    if (!isClient) return null
    return (
        <FontAwesomeIcon onClick={onClick} icon={icon} size={size} className={`${className} ${onClick && 'click animated'}`} color={color} />
    )
}