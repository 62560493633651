import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { faBell, faBook, faComputer, faFutbol, faGuitar, faHeart, faMoneyBill, faMusic, faNewspaper, faStarOfDavid, faTrophy } from "@fortawesome/free-solid-svg-icons"
import { ICategory, IRegion, IStation, ITrack, IType } from "@models"
import { IMetaData } from "@types"
import dayjs from "dayjs"
import { Metadata } from "next"

export const Logger = {
    log: (message?: any, ...optionalParams: any[]) => {
        console.log(`${message} `, ...optionalParams)
    },
    error: (message?: any, ...optionalParams: any[]) => {
        console.log(`\u001b[1;41m ${message}`, ...optionalParams, "\u001b[0m")
    },
    warn: (message?: any, ...optionalParams: any[]) => {
        console.log(`\u001b[1;43m ${message}`, ...optionalParams, "\u001b[0m")
    },
    info: (message?: any, ...optionalParams: any[]) => {
        console.log(`\u001b[1;44m ${message}`, ...optionalParams, "\u001b[0m")
    },
}
export const TOP_AMOUNT = 25
export const __DEV__ = process.env.NODE_ENV === 'development'
export const CATEGORIES: { [key in ICategory]: {
    name: string
    icon: IconProp
    snipped: string
    title?: string
    description?: string
}} = {
    favorites: {
        name: "המועדפים שלי",
        icon: faHeart,
        title: "תחנות רדיו ישראלי מועדפות",
        description: "האזינו לתחנות הרדיו המועדפות שלכם ברדיו הד - כל תחנות הרדיו המועדפות שלכם במקום אחד.",
        snipped: `מחפשים את תחנות הרדיו האהובות עליכם ביותר? בקטגוריית "המועדפים" תמצאו את התחנות הפופולריות ביותר המעניקות חוויית האזנה מגוונת ומרתקת לכל קהלי היעד. התחנות כאן נבחרו בקפידה על פי דירוגים והעדפות המאזינים ומספקות שילוב מושלם של מוזיקה, חדשות ועדכונים חיים מכל הארץ. הצטרפו לאלפי מאזינים שכבר מצאו את התחנה שלהם בקטגוריית המועדפים!`
    },
    top: {
        name: `${TOP_AMOUNT} המובילות`,
        icon: faTrophy,
        title: `${TOP_AMOUNT} התחנות המובילות ברדיו הד`,
        description: `האזינו ל${TOP_AMOUNT} התחנות המובילות ברדיו הד - תחנות הרדיו הפופולריות ביותר באתר.`,
        snipped: `תחנות הרדיו המובילות בישראל במקום אחד! קטגוריה זו כוללת את התחנות הפופולריות ביותר בארץ, כולל גלגלצ, רשת ב, רשת ג, גלי צה"ל ועוד תחנות פופולריות רבות אחרות. האזינו עכשיו לכל מה שחם ומוביל ברדיו הישראלי.`
    },
    news: {
        name: "חדשות",
        icon: faNewspaper,
        snipped: `עדכונים חיים, דיווחים שוטפים ופרשנויות מרתקות מכל רחבי הארץ והעולם. בקטגוריית החדשות תמצאו את התחנות המרכזיות כולל רשת ב, גלגלצ, גלי צה"ל ותחנות נוספות המסקרות את האירועים החמים בזמן אמת.`
    },
    music: {
        name: "מוזיקה",
        icon: faMusic,
        snipped: `מוזיקה ללא הפסקה מכל הסגנונות האהובים – ממוזיקה ים תיכונית, דרך פופ ישראלי ועד מוזיקה קלאסית. התחנות בקטגוריה זו כוללות את 88FM, רדיו תל אביב, קול המוסיקה ועוד.`
    },
    sport: {
        name: "ספורט",
        icon: faFutbol,
        snipped: `לאוהדי הספורט האמיתיים! כאן תמצאו תחנות רדיו המסקרות את כל סוגי הספורט - כדורגל, כדורסל, אירועי ספורט בינלאומיים ועוד. התחנות כוללות את 103FM, רדיו חיפה ועוד תחנות מובילות בתחומם.`
    },
    mizrahit: {
        name: "מזרחית / ים תיכונית",
        icon: faGuitar,
        snipped: `גם אתם מתים על מזרחית ולא מצליחים לבחור איזה שיר לשמוע? מוזיקה ים תיכונית איכותית ומזרחית אותנטית מכל רחבי הארץ. תחנות כמו חם אש, קול הים האדום, קצב ים תיכוני ועוד יכניסו אתכם לאווירה ישראלית חמה ושמחה.`
    },
    religion: {
        name: "דת",
        icon: faStarOfDavid,
        snipped: `למאזינים המחפשים תוכן דתי ורוחני – תחנות כמו מורשת, קול ברמה, והמוזיקה היהודית מציעות שידורים תורניים ומוזיקה יהודית אותנטית.`
    },
    education: {
        name: "חינוך",
        icon: faBook,
        snipped: `תחנות הרדיו המספקות תוכן חינוכי, תרבותי וחברתי לקהל הרחב. כאן תמצאו תחנות כמו קול הנגב, קול יזרעאל, אורנים ועוד, המספקות תוכן עשיר לתלמידים, סטודנטים ומשפחות.`
    },
    economy: {
        name: "כלכלה",
        icon: faMoneyBill,
        snipped: `קטגוריית כלכלה מספקת מידע פיננסי, חדשות כלכליות וניתוחים עדכניים. תחנות כמו רשת א ועסקים מביאות לכם את כל מה שחם בתחום הכלכלה בישראל.`
    },
    silence: {
        name: "הגל השקט",
        icon: faBell,
        snipped: `הגל השקט הינו השם שתחנות רדיו מקבלות, אשר תפקידן הוא לשדר אך ורק ברגע של אזעקת אמת. בדרך כלל ברגע של אזעקת אמת, אחת מתחנות הרדיו המשדרות את הגל השקט באזורן יפרצו את הדממה בשידור אזעקה.`
    }
}
export const TYPES: { [key in IType]: {
    name: string
    order: number
    snipped: string
    icon?: IconProp
} } = {
    national: {
        name: "ארצי",
        order: 1,
        snipped: `תחנות הרדיו הארציות המובילות בישראל, כולל רשת ב, רשת ג, גלגלצ, גלי צה"ל ועוד. האזינו עכשיו לתחנות הרדיו הארציות המובילות בארץ.`
    },
    regional: {
        name: "אזורי",
        order: 2,
        snipped: `תחנות הרדיו האזוריות המובילות בישראל, כולל רדיו תל אביב, רדיו חיפה, רדיו ירושלים ועוד. האזינו עכשיו לתחנות הרדיו האזוריות המובילות בארץ.`
    },
    university: {
        name: "אקדמאי",
        order: 4,
        snipped: `תחנות הרדיו האקדמאיות המובילות בישראל, כולל רדיו קול הנגב, רדיו קול יזרעאל, רדיו אורנים ועוד. האזינו עכשיו לתחנות הרדיו האקדמאיות המובילות בארץ.`
    },
    internet: {
        name: "אינטרנטי",
        order: 5,
        snipped: `תחנות הרדיו האינטרנטיות המובילות בישראל, כולל רדיו קול המוסיקה, רדיו קול הים האדום, רדיו קול יזרעאל ועוד. האזינו עכשיו לתחנות הרדיו האינטרנטיות המובילות בארץ.`
    }
}

export const METADATA: IMetaData = {
    home: {
        title: "רדיו ישראלי - שידור חי אונליין" + " - " + process.env.NEXT_PUBLIC_SITE_NAME,
        description: "היכנסו להאזין אונליין לכל תחנות הרדיו הישראלי באתר רדיו הד - ההאזנה מיידית, בחינם ואינה דורשת התקנת תוכנה.",
    },
    notfound: {
        title: "העמוד לא נמצא" + " - " + process.env.NEXT_PUBLIC_SITE_NAME,
        description: "העמוד שחיפשת לא נמצא - חפש אחרי תחנות רדיו אחרות באתר רדיו הד."
    },
    sitemap: {
        title: "מפת אתר רדיו ישראלי" + " - " + process.env.NEXT_PUBLIC_SITE_NAME,
        description: "מפת אתר רדיו הד מפרטת את כל תחנות הרדיו המושמעות באתר, בנוסף למאמרים, תוכניות רדיו ועוד.",
    },
    search: {
        title: "חיפוש תחנות רדיו" + " - " + process.env.NEXT_PUBLIC_SITE_NAME,
        description: "חיפוש תחנות רדיו באתר רדיו הד - חפשו תחנות רדיו לפי סוג וקטגוריה.",
    },
    terms: {
        title: "תנאי שימוש באתר רדיו הד" + " - " + process.env.NEXT_PUBLIC_SITE_NAME,
        description: "תנאי שימוש באתר רדיו הד מגדירים את התנאים לשימוש באתר ובשירותיו.",
    },
    privacy: {
        title: "מדיניות פרטיות באתר רדיו הד" + " - " + process.env.NEXT_PUBLIC_SITE_NAME,
        description: "מדיניות פרטיות באתר רדיו הד מגדירה את השימוש במידע האישי שנאסף באתר.",
    },
    contact: {
        title: "צור קשר עם רדיו הד" + " - " + process.env.NEXT_PUBLIC_SITE_NAME,
        description: "צור קשר עם צוות רדיו הד בכל נושא שתרצו.",
    },
    admin: {
        title: "ניהול תחנות רדיו" + " - " + process.env.NEXT_PUBLIC_SITE_NAME,
        description: "ניהול תחנות הרדיו באתר רדיו הד - עריכת תחנות, סדר תחנות ועוד.",
    },
    frequencies: {
        title: "תדרים של תחנות רדיו" + " - " + process.env.NEXT_PUBLIC_SITE_NAME,
        description: "כל תדרי השידור של תחנות גלגלצ, רשת ב, רשת ג, גלי ישראל, גלצ ועוד במקום אחד - וגם - האזנה אונליין מכל מקום בכל זמן.",
    },
}
type MetadataKey = keyof typeof METADATA;

export const getStaticMetadata = (key: MetadataKey): Metadata => {
    return {
        ...METADATA[key],
        openGraph: {
            ...METADATA[key],
            url: `${process.env.NEXT_PUBLIC_URL}${key}`,
            images: [
                {
                  url: `${process.env.NEXT_PUBLIC_URL}images/logos/1200_1200.webp`,
                  width: 1200,
                  height: 1200,
                  alt: process.env.NEXT_PUBLIC_SITE_NAME
                },
                {
                  url: `${process.env.NEXT_PUBLIC_URL}images/logos/1200_675.webp`,
                  width: 1200,
                  height: 675,
                  alt: process.env.NEXT_PUBLIC_SITE_NAME
                }
            ]
        }
    }
}

export const numberWithCommas = (x: number = 0) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const REGIONS: {[key in IRegion]: string} = {
    north: 'צפון',
    center: 'מרכז',
    south: 'דרום',
    jerusalem: 'ירושלים',
    east: 'מזרח'
}
export const STATION_FULL_NAME = (name: string) => {
    return name.includes("רדיו ")? name: `רדיו ${name}`
}

export const STATIONS_LD_JSON = (stations: IStation[]) => {
    const jsonLDListItems = stations.map((station, index) => {
        const full_name = STATION_FULL_NAME(station.name)
        const desc = station.meta?.description || `מה ${full_name} מנגן עכשיו? ${full_name} אונליין באתר ${process.env.NEXT_PUBLIC_SITE_NAME} - היכנסו והאזינו לשידור החי של ${full_name} ולשאר תחנות הרדיו הישראליות בחינם וללא צורך בהתקנת תוכנה.`
        return {
          "@context":"http://schema.org",
          "@type": "ListItem",
          "position": index + 1,
          "url": `${process.env.NEXT_PUBLIC_URL}station/${station.id}/`,
          "name": station.name,
          "image": `${process.env.NEXT_PUBLIC_URL}images/stations/${station.id}.webp`,
          "description": desc
        }
    })
    const jsonLD = 
        {
          "@context":"http://schema.org",
          "@type":"ItemList",
          "name":"Stations",
          "numberOfItems": stations.length,
          "itemListElement": jsonLDListItems
        }
      
    return jsonLD
}

export const STATIONS_LD_FAQ = (station: IStation) => {
    const baseQuestions = [
        {
          question: `איך להאזין לשידור החי של ${station.name} אונליין?`,
          answer: `${station.name} משדר אונליין בשידור חי. ניתן להאזין לשידור החי באתר שלנו, במובייל או באפליקציה על ידי לחיצה על כפתור ההפעלה.`,
        },
        {
          question: `האם ניתן להאזין ל${station.name} במובייל?`,
          answer: `כן, ניתן להאזין ל${station.name} במובייל באמצעות אתר המובייל שלנו או באפליקציה.`,
        }
    ]
    
    const frequencyQuestions = [];
    if (station.freqs && station.freqs.regions) {
        const allFrequencies = Object.entries(station.freqs.regions)
          .map(([region, freqData]) =>
            Object.entries(freqData || {})
              .map(([freq, cities]) => `${freq} ב${cities.join(', ')}`)
              .join('; ')
          )
          .join('; ')
        if (allFrequencies) {
            frequencyQuestions.push({
                question: `באילו תדרים ${station.name} משדר?`,
                answer: `תחנת הרדיו ${station.name} משדרת בתדרים: ${allFrequencies}.`,
            })
        }
        Object.entries(station.freqs.regions).forEach(([region, freqData]) => {
            Object.entries(freqData || {}).forEach(([freq, cities]) => {
                cities.forEach((city) => {
                frequencyQuestions.push({
                    question: `באיזה תדר ${station.name} משדר באזור ${city}?`,
                    answer: `${station.name} משדר באזור ${city} בתדר ${freq}fm.`,
                })
                })
            })
        })
      }
    
      return {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
            mainEntity: [...baseQuestions, ...frequencyQuestions].map(({ question, answer }) => ({
            '@type': 'Question',
            name: question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: answer,
            }
        }))
    }
}

export const STATIONS_LD_EVENT = (station: IStation) => {
    return {
        "@context": "https://schema.org",
        "@type": "Event",
        "organizer": {
            "@type": "Organization",
            "name": process.env.NEXT_PUBLIC_SITE_NAME,
            "url": process.env.NEXT_PUBLIC_URL
        },
        "offers": {
            "@type": "Offer",
            "url": `${process.env.NEXT_PUBLIC_URL}station/${station.id}/`,
            "price": "0",
            "priceCurrency": "ILS",
            "availability": "InStock",
            "validFrom": dayjs().startOf('day').toISOString(),
        },
        "image": `${process.env.NEXT_PUBLIC_URL}images/stations/${station.id}.webp`,
        "description": station.meta?.description || `מה ${station.name} מנגן עכשיו? ${station.name} אונליין באתר ${process.env.NEXT_PUBLIC_SITE_NAME} - היכנסו והאזינו לשידור החי של ${station.name} ולשאר תחנות הרדיו הישראליות בחינם וללא צורך בהתקנת תוכנה.`,
        "eventStatus": "https://schema.org/EventScheduled",
        "name": `${station.name} - לייב אונליין`,
        "startDate": dayjs().startOf('day').toISOString(),
        "endDate": dayjs().endOf('day').toISOString(),
        "eventAttendanceMode": "https://schema.org/OnlineEventAttendanceMode",
        "location": {
            "@type": "VirtualLocation",
            "url": `${process.env.NEXT_PUBLIC_URL}station/${station.id}/`
        },
        "performer": {
            "@type": "PerformingGroup",
            "name": STATION_FULL_NAME(station.name)
        }
    }
}
export const STATIONS_LD_ORGANIZATION = (station: IStation) => {
    return {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": STATION_FULL_NAME(station.name),
        "url": `${process.env.NEXT_PUBLIC_URL}station/${station.id}/`,
        "logo": `${process.env.NEXT_PUBLIC_URL}images/stations/${station.id}.webp`
    }
}
export const STATIONS_LD_CHANNEL = (station: IStation) => {
    return {
        "@context": "https://schema.org",
        "@type": "RadioChannel",
        "name": station.name,
        ...(station.freq? {"broadcastFrequency": station.freq.replace("FM", " FM")}: ''),
        "inLanguage": "he",
        "broadcastServiceTier": "Free",
        "providesBroadcastService": {
        "@type": "BroadcastService",
        "name": `${station.name} לייב אונליין`,
        "url": `${process.env.NEXT_PUBLIC_URL}station/${station.id}/`
        }
    }
}
export const STATIONS_LD_PLAYLIST = (station: IStation, playlist: ITrack[]) => {
    return {
        "@context": "https://schema.org",
        "@type": "MusicPlaylist",
        "name": `${station.name} - שירים אחרונים`,
        "description": `האזינו לשירים אחרונים של ${station.name} באתר ${process.env.NEXT_PUBLIC_SITE_NAME} - האזנה מיידית ובחינם לשירים החמים ביותר.`,
        "numTracks": playlist.length,
        "track": playlist.map((track, index) => ({
            "@type": "MusicRecording",
            "name": track.name,
            "byArtist": {
                "@type": "MusicGroup",
                "name": track.artists.join(', ')
            },
            "inAlbum": {
                "@type": "MusicAlbum",
                "name": track.album
            },
            "duration": `PT${Math.floor(track.duration_ms / 60000)}M${Math.floor((track.duration_ms % 60000) / 1000)}S`
        }))
    }
}
export const OPEN_SPOTIFY = (url: string) => {
    const timeout = setTimeout(() => {
        window.open(url, '_blank')
    }, 500)
    window.location.href = url.replace('https://open.spotify.com', 'spotify:/')
    window.addEventListener('blur', () => clearTimeout(timeout))
}